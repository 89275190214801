<template>
  <!-- 关于我们 -->
  <div class="zz_aboutuser1">
    <div
      class="current-nav centerText"
      v-if="Object.keys(lxData).length != 0"
      :style="{
        background: `url(${lxData.baseImg}) 100% no-repeat`
      }"
    >
      <h1>{{ lxData.baseTitle }}</h1>
      <h2>{{ lxData.baseText }}</h2>
    </div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="zz_aboutuser1_nav">
      <div class="current-title">
        <span>{{ youshiData.youshiTitle }}</span>
        <p>
          {{ youshiData.youshiText }}
        </p>
      </div>
      <ul class="nav_youshi">
        <li v-for="(el, i) in youshiData.youshiList2" :key="i">
          <i :style="{ background: `url(${el.value}) no-repeat 0 0` }"></i>
          <p>{{ el.key }}</p>
        </li>
      </ul>
    </div>
    <div class="zz_aboutuser1_nav">
      <div class="current-title">
        <span>{{ jieshaoData.jieshaoTitle }}</span>
        <p>
          {{ jieshaoData.jieshaoText }}
        </p>
      </div>
      <div class="nav_container">
        <div class="nav_container_left">
          <img :src="jieshaoData.jieshaoImg" alt="" style="width:100%" />
        </div>
        <div class="nav_container_right" v-html="jieshaoData.jieshaoHtml"></div>
      </div>
    </div>
    <div class="zz_aboutuser1_nav">
      <div class="current-title">
        <span>{{ hezuoData.hezuoTitle }}</span>
        <p>
          {{ hezuoData.hezuoText }}
        </p>
      </div>
      <ul class="current-qyxz">
        <li v-for="(el, i) in hezuoData.hezuoList" :key="i">
          <img :src="el" alt="" style="width:100%" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      lxData: {},
      youshiData: {},
      jieshaoData: {},
      hezuoData: {}
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.lxData = res.data.data.baseMsg
          this.youshiData = res.data.data.youshi
          this.jieshaoData = res.data.data.jieshao
          this.hezuoData = res.data.data.hezuo
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zz_aboutuser1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zz_aboutuser1_nav {
    background: #f2f2f2;
    .nav_container {
      width: 1100px;
      background-color: #fff;
      padding: 50px;
      display: flex;
      justify-content: space-between;
      .nav_container_left {
        width: 49%;
      }
      .nav_container_right {
        width: 49%;
        line-height: 1.4;
        color: #666666;
        text-align: justify;
      }
    }
    .nav_youshi {
      display: flex;
      width: 1100px;
      margin: 0 auto;
      justify-content: space-between;
      background-color: #fff;
      padding: 50px;
      li {
        width: 20%;
        text-align: center;
        color: #000;
        margin: 0 1.5%;
        i {
          display: inline-block;
          width: 100px;
          height: 100px;
          margin: 4% auto;
          background-position-x: center !important;
        }
        p {
          line-height: 1.4;
        }
      }
    }
    .current-qyxz {
      display: flex;
      flex-wrap: wrap;
      border-right: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
      width: 86%;
      margin: 0 auto;
      li {
        width: 25%;
        border-left: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        box-sizing: border-box;
        img {
          opacity: 0.8;
          filter: grayscale(100%);
          transition: 0.3s;
          cursor: pointer;
        }
      }
      li:hover img {
        opacity: 1;
        filter: none;
      }
    }
  }
  .zz_aboutuser1_nav:last-child {
    padding: 0 0 80px;
  }
}
.nav_container {
  padding: 0 15px;
  margin: 0 auto;
}
</style>
